.map-container {
  height: calc(100dvh - 11rem);
}

.map-container .vehicle-search-input {
  top: 1rem;
  font-size: 1.6rem;
  color: #D01400;
  text-align: center;
  width: 28rem;
  z-index: 3;
}
.map-container .vehicle-search-input.mobile {
  top: auto;
  bottom: 2rem;
}
.map-container .vehicle-search-input .ant-select-selector {
  border-color: #D01400;
}
.map-container .vehicle-search-input .ant-select-selection-search-input,
.map-container .vehicle-search-input .ant-select-selection-item {
  text-align: center !important;
  color: #D01400 !important;
}

.vehicle-status-panel {
  width: 30rem;
  height: 20rem;
  top: 0;
  right: 0;
  color: black;
  background-color: white;
  z-index: 3;
  box-shadow: -0.2rem 0.2rem 0.5rem grey;
  border-radius: 1.4rem;
  padding: 1rem 2rem 1rem 2rem;
  font-size: 1.6rem;
  transition: all .5s;
}
.vehicle-status-panel .title {
  font-size: 1.8rem;
}
.vehicle-status-panel .circle {
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  margin-top: .6rem;
  margin-right: 1rem;
}
.vehicle-status-panel .toggle-button {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #6C9EFF;
  font-size: 1.2rem;
  text-align: center;
  background-color: #DFE0EB;
  cursor: pointer;
  width: 1.5rem;
  height: 5rem;
  padding-top: 1.5rem;
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}
.vehicle-status-panel .toggle-button:hover {
  background-color: #264aee;
}
.vehicle-status-panel .toggle-button .left {
  display: none;
}

.vehicle-status-panel.hidden {
  right: -28.5rem;
}
.vehicle-status-panel.hidden .toggle-button .left {
  display: inline;
}
.vehicle-status-panel.hidden .toggle-button .right {
  display: none;
}



@media(max-width:767px) {
  .map-container {
    height: 100dvh;
  }
  .vehicle-status-panel {
    top: 3rem;
  }
}
