.new-ticket-main {
  font-size: 1.6rem;
}

.sub1-subsub1-sub1-new-ticket-div1 :nth-child(1) {
  font-family: rmedium;
  color: #7A7D8B;
}

.sub1-subsub1-sub1-new-ticket-div1 :nth-child(2) {
  height: 4.3rem;
  border-radius: 1rem;
  border: .1rem solid #DFE0EB;
  font-size: 1.4rem;
  padding-left: 1rem;
  outline: none;
  font-family: rregular;
}

.sub2-subsub1-sub1-new-ticket-div1 label {
  font-family: rmedium;
  color: #7A7D8B;
}
.sub2-subsub1-sub1-new-ticket-div1 span {
  font-size: 1.2rem;
  color: #7A7D8B;
}

.sub2-subsub1-sub1-new-ticket-div1 textarea {
  height: 12rem;
  max-height: 12rem;
  border-radius: 1rem;
  border: .1rem solid #DFE0EB;
  font-size: 1.4rem;
  padding: 1rem 1.5rem;
  outline: none;
  font-family: rregular;
}

.sub1-left-subsub2-sub1-new-ticket-div1 {
  width: 20rem;
  border-radius: .5rem;
  overflow: hidden;
  border: 1px solid #00000054;
  background-color: #EEF0F5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  gap: .5rem;
}

.sub1-left-subsub2-sub1-new-ticket-div1 a {
  font-family: rregular;
  font-size: 1.6rem;
  color: #7A7D8B;
  word-break: break-all;
  text-decoration: none;
}

.sub1-left-subsub2-sub1-new-ticket-div1 img {
  right: 1rem;
  top: .8rem;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

.sub1-left-subsub2-sub1-new-ticket-div1:hover {
  background-color: #b9dbfd;
}
.sub1-left-subsub2-sub1-new-ticket-div1:active {
  background-color: #acf5b2;
}

.sub1-center-subsub2-sub1-new-ticket-span {
  font-size: 1.3rem;
  color: #7A7D8B;
  font-family: rregular;
  padding-top: 0.5rem
}

.sub1-right-subsub2-sub1-new-ticket-div1 input {
  opacity: 0;
  width: 3rem;
  height: 3rem;
  top: 0rem;
  left: 0rem;
}

.sub1-right-subsub2-sub1-new-ticket-div1 img {
  margin: 0.25rem;
  width: 3rem;
  height: 3rem;
  transition: all .5s;
}

.sub1-right-subsub2-sub1-new-ticket-div1:hover img {
  margin: 0;
  width: 3.5rem;
  height: 3.5rem;
  transform: rotate(180deg);
}

.new-ticket-main .tab-button {
  width: 14rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

@media(max-width:768px) {
  .new-ticket-main {
    padding: 2rem 1rem;
  }

  .new-ticket-main p {
    text-align: center;
    padding-left: 0;
  }

  .new-ticket-div1 {
    margin: 2rem 0 2rem 0 !important;
    width: 100%;
  }

  .sub1-subsub1-sub1-new-ticket-div1 :nth-child(2) {
    width: 100%;
  }
}
