.reports-main .tab-button label {
  font-size: 1.6rem;
}

.reports-main .tab-button .down-arrow {
  width: 0.9rem;
  height: 0.5rem;
}

.reports-main .field-input input {
  border: none;
  text-align: center;
}

.subsub1-sub1-reports-div2 {
  height: 4.3rem;
  background-color: #364153;
  display: grid;
  grid-template-columns: .15fr .8fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "h1 h2 h3 h4 h5 h6";
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.subsub1-sub1-reports-div2 p {
  font-size: 1.6rem;
  color: white;
  font-family: rbold;
}

.subsub1-sub1-reports-div2 :nth-child(1) {
  grid-area: h1;
}

.subsub1-sub1-reports-div2 input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 1.7rem;
  width: 1.7rem;
  background-color: #364153;
  border-radius: .5rem;
  cursor: pointer;
  border: .2rem solid #7A7D8B;
}

.subsub1-sub1-reports-div2 input[type="checkbox"]:after {
  font-family: "Font Awesome 6 Free";
  content: "\f00c";
  font-size: 1.3rem;
  color: #FF0000;
  display: none;
  font-weight: 1000;
}

.subsub1-sub1-reports-div2 input[type="checkbox"]:checked {
  background-color: #364153;
}

.subsub1-sub1-reports-div2 input[type="checkbox"]:checked::after {
  display: block;
}

#import-reports {
  display: flex;
  margin-top: .5rem;
}

#sub1-import-reports {
  background-color: white;
  width: 2.7rem;
  height: 2.7rem;
  font-size: 1.6rem;
  text-align: center;
  margin-top: 1.3rem;
  border-top: .1rem solid #DFE0EB;
  border-bottom: .1rem solid #DFE0EB;
  border-left: .1rem solid #DFE0EB;
}

.subsub1-sub2-reports-div2 {
  width: 100%;
  height: 5.2rem;
  border: .1rem solid #DFE0EB;
  background-color: white;
  display: grid;
  grid-template-columns: .15fr .8fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "b1 b2 b3 b4 b5 b6";
  border-radius: 1rem;
}

.subsub1-sub2-reports-div2 .item {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.subsub1-sub2-reports-div2 p {
  font-family: rregular;
  color: #7A7D8B;
  font-size: 1.5rem;
}

.subsub1-sub2-reports-div2 :nth-child(1) {
  grid-area: b1;
}

.subsub1-sub2-reports-div2 input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 1.7rem;
  width: 1.7rem;
  background-color: white;
  border-radius: .5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: .2rem solid #7A7D8B;
}

.subsub1-sub2-reports-div2 input[type="checkbox"]:after {
  font-family: "Font Awesome 6 Free";
  content: "\f00c";
  font-size: 1.3rem;
  color: #FF0000;
  display: none;
  padding-top: .2rem;
  font-weight: 1000;
}

.subsub1-sub2-reports-div2 input[type="checkbox"]:checked {
  background-color: white;
}

.subsub1-sub2-reports-div2 input[type="checkbox"]:checked::after {
  display: block;
}

.portal-report-types {
  border-radius: .7rem;
  box-shadow: -.1rem .1rem .5rem grey;
  border: .1rem solid grey;
  font-size: 1.6rem;
}
.portal-report-types button {
  border-radius: .5rem;
}
.portal-report-types button:hover {
  background-color: rgba(112, 203, 255, 0.4);
}


@media(max-width:767px) {
  .reports-main {
    padding: 5rem 1rem 0 1rem;
  }

  .subsub1-sub2-reports-div2 {
    height: inherit;
    background-color: #364153;
    border-radius: 1rem;
  }

  .reports-main .sub2-div2 {
    height: calc(100svh - 38rem) !important;
  }
}