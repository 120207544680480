.history-search-panel-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  padding: .5rem 1rem 0 .5rem;
  z-index: 2;
}

.history-search-panel {
  border: .1rem solid #DFE0EB;
  border-radius: 2rem;
  background-color: #FFFFFF;
  height: 7rem;
  padding: .5rem;
  font-size: 1.6rem;
}

.history-search-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.history-search-panel .panel-title {
  color: #F22222;
  font-family: rmedium;
}

.history-search-panel .vehicle-name {
  color: #F22222;
  font-family: rmedium;
  text-decoration: underline;
}

.history-playback-panel {
  position: absolute;
  top: .5rem;
  left: .5rem;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #364153;
  border-radius: 2rem;
  z-index: 2;
  color: white;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2.5rem;
  padding: .8rem 1.5rem;
  text-align: center;
}

.player-main {
  width: 96.5rem;
  height: 7.9rem;
  background-color: black;
  border-radius: 1.6rem;
  position: absolute;
  bottom: 1rem;
  display: flex;
  align-items: center;
  z-index: 2;
  user-select: none !important;
}

.left-player-main {
  width: 12rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sub1-player-main-div1 {
  height: 80%;
  width: 100%;
  /* background-color: aquamarine; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 1.5rem;
}

.subsub1-sub1-player-main-div1 {
  font-size: 2.4rem;
  color: white;
  cursor: pointer;

}

.subsub2-sub1-player-main-div1 {
  width: 3.2rem;
  height: 3.2rem;
  font-size: 1.2rem;
  color: white;
  background-color: #EB5757;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: .8rem;
  box-shadow: .1rem .3rem 1rem #EB5757;
  cursor: pointer;
}

.subsub3-sub1-player-main-div1 {
  font-size: 2.4rem;
  color: white;
  cursor: pointer;
}

.sub2-player-main-div1 {
  height: 20%;
  width: 100%;
  margin-bottom: .5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub2-player-main-div1 p {
  width: 3rem;
  font-size: 1.2rem;
  text-align: center;
  color: white;
  font-family: rregular;
  cursor: pointer;
  margin-top: 1rem;
}

.right-player-main {
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
}

.sub1-right-player-main {
  display: flex;
  padding-top: 3rem;
}

.subsub1-sub1-right-player-main {
  font-size: 1.2rem;
  color: white;
  font-family: rregular;
  margin-right: 1rem;
  text-align: center;
  width: 12rem;
}

.subsub2-sub1-right-player-main {
  flex-grow: 1;
  padding-right: 2rem;
}

input[type="range"] {
  width: 100%;
  accent-color: #EB5757;
  height: .8rem;
  border-radius: .8rem;
  outline: none;
  cursor: pointer;

  -webkit-appearance: none;
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 2em + var(--ratio) * (100% - 2em));
}
input[type=range]::-webkit-slider-runnable-track {
  height: .8rem;
  border-radius: .8rem;
  background: linear-gradient(#EB5757,#EB5757) 0/var(--sx) 100% no-repeat, #616161;
}

/* input[type="range"]::-moz-range-thumb, */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 2rem;
  height: 2rem;
  margin-top: -.6rem;
  border-radius: 50%;
  background: white;
  border: .2rem solid black;
  cursor: pointer;
}

.sub2-right-player-main {
  display: flex;
  justify-content: flex-end;
  margin-right: 5rem;
  margin-top: 1.2rem;
}

.sub2-right-player-main p {
  font-size: 1.2rem;
  color: white;
}

.sub2-right-player-main span {
  font-size: 1.2rem;
  color: white;
  margin-left: 1rem;
  margin-right: 1rem;
}

@media(max-width:767px) {
  .history-search-panel-wrapper {
    top: 3rem;
  }
  .history-search-panel {
    flex-direction: column;
    height: fit-content;
    padding: 1.5rem;
  }

  .history-playback-panel {
    top: 3rem;
  }
  .player-main {
    width: 100%;
    left: 0;
  }
}