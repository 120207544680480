
.react-datepicker {
  padding: 2rem 2rem 1rem 2rem;
  box-shadow: 2px 16px 19px 0px #00000017, 0px 4px 4px 0px #00000040;
  border-color: #7A7D8B66;
  border-radius: .8rem;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__close-icon::after {
  background-color: #cccccc;
}

.react-datepicker__input-container>input {
  outline: none;
  border-radius: .5rem;
  border: .1rem solid #DFE0EB;
  text-align: center;
  width: 25rem;
  max-width: 100%;
}

.react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
  padding: 0;
  margin-bottom: 1.6rem;
}

.react-datepicker__current-month {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 2rem;
  color: #4A5660;
}

.react-datepicker__navigation--previous {
  top: 2rem;
  left: 2rem;
}

.react-datepicker__navigation--next {
  top: 2rem;
  right: 2rem;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day-name {
  color: #c3c4cf !important;
  font-family: rregular;
  font-size: 1.2rem;
  width: 3rem;
  height: 2rem;
  margin: .2rem;
}

.react-datepicker__day {
  width: 3rem;
  height: 3rem;
  font-family: rregular;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 3rem;
  border: 1px solid transparent;
  border-radius: 1.5rem;
  margin: .2rem;
  color: #4A5660;
}

.react-datepicker__day:hover {
  border-radius: 1.5rem;
}

.react-datepicker__day--today {
  border-color: #F04D23;
  border-radius: 30px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--keyboard-selected:hover {
  background-color: #f0f0f0;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  color: white;
}

.react-datepicker__day--selected {
  color: white;
  background-color: #F04D23;
}

.react-datepicker__day.react-datepicker__day--outside-month {
  color: #aaa;
}
.react-datepicker__time-container {
  display: none !important;
}
.react-datepicker__input-time-container {
  margin: .5rem 0;
  text-align: center;
}

.react-datepicker-time__input {
  border: .1rem solid #7A7D8B;
  border-radius: .5rem;
}

.react-datepicker-time__caption {
  display: none !important;
}
.react-datepicker-time__input>input {
  color: #4A5660;
  font-size: 1.4rem;
  font-weight: 600;
  outline: none;
  border: none;
  text-align: center;
}
.react-datepicker__close-icon {
  display: none !important;
}
.date-picker-input {
  position: relative;
}
.date-picker-input > img {
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: .4rem;
  right: 1rem;
}

.react-datepicker__children-container {
  width: 100%;
  text-align: center;
}

.react-datepicker__children-container > button {
  background-color: #364153;
  border-radius: 1rem;
  color: white;
  font-size: 1.4rem;
  width: 12rem;
  margin-top: 1rem;
}
.react-datepicker__children-container > button:hover {
  background-color: #0d2758;
}

.react-datepicker__day--highlighted {
  position: relative;
}
.react-datepicker__day--highlighted:not(.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range) {
  background-color: inherit;
}
.react-datepicker__day--highlighted::after {
  content: "";
  position: absolute;
  width: .7rem;
  height: .7rem;
  border-radius: 50%;
  background-color: #28A745;
  bottom: -0.3rem;
  left: 50%;
  margin-left: -0.3rem;
}