@font-face {
  font-family: rbold;
  src: url('../public/font/Roboto-Bold.ttf')
}

@font-face {
  font-family: rregular;
  src: url('../public/font/Roboto-Regular.ttf')
}

@font-face {
  font-family: rmedium;
  src: url('../public/font/Roboto-Medium.ttf')
}

@font-face {
  font-family: rlight;
  src: url('../public/font/Roboto-Light.ttf')
}

@font-face {
  font-family: msemi;
  src: url('../public/font/Mulish-SemiBold.ttf')
}

* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

#root {
  height: 100dvh;
  overflow: hidden;
}

/* common */
.horizontal-bar {
  border-top: .1rem solid #7A7D8B;
}

.vertical-bar {
  border-left: .1rem solid #7A7D8B;
}

.cursor-pointer {
  cursor: pointer;
}

button {
  border: none;
  cursor: pointer;
  appearance: none;
  background-color: inherit;
  transition: transform .7s ease-in-out;
}

.bg-black-90 {
  background-color: rgba(0, 0, 0, .9);
}

.red-black {
  color: #D01400;
}

.bg-red-black {
  background-color: #D01400;
}

.green-black {
  color: #28A745;
}


/* end */

.css-18nc3u2 .MuiOutlinedInput-root {
  padding: 5px !important;
}

.css-18nc3u2 .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  right: 9px !important;
  top: 8px !important;
}

.right-layout {
  border-top-left-radius: 5rem;
  background-color: #F7F8FC;
  border-top: 1px solid rgb(0, 0, 0, 20%);
  border-left: 1px solid rgb(0, 0, 0, 20%);
}

/* auth */
#auth {
  background-color: pink;
  display: flex;
}

#auth .auth-left {
  background-color: rgb(179, 29, 54);
}

#auth .auth-left img {
  object-fit: cover;
  width: 100%;
  height: 100dvh;
}

#auth .auth-right {
  background-color: #0E0035;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#auth .auth-right .main-logo {
  width: 350px;
  height: 10rem;
}

#auth .sub1-auth-right {
  width: 90%;
}

.main-form {
  background-color: rgb(178, 178, 178, 7%);
  border-radius: 5.6rem;
  height: auto;
  padding: 30px;
}

#auth .subsub1-sub1-auth-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

#auth .subsub1-sub1-auth-right h3 {
  font-size: 4rem;
  color: white;
  font-weight: 600;
  margin-bottom: 5rem;
}

#auth .input-container {
  width: 100%;
  background-color: rgb(255, 255, 255, 0%);
  margin-bottom: 3rem;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

#auth .input-container > img {
  margin-right: 1rem;
  width: 50px;
}

#auth .input-container>input,
#auth .input-container .ant-select-selector,
#auth .input-container .phone-number-input {
  width: 300px;
  min-height: 53px;
  font-size: 2rem;
  background-color: rgb(255, 255, 255, 0%);
  color: red;
  font-family: rlight;
  text-align: start;
  border: 0;
  outline: none;
  border-bottom: .1rem solid white;
  border-radius: 0;
  padding: 0;
}
#auth .input-container .phone-number-input input {
  color: red;
  background-color: rgb(255, 255, 255, 0%);
  border: none;
  line-height: 52px;
  height: 52px;
  padding: 0;
  margin: 0;
}
#auth .input-container>input:focus {
  color: white;
}
#auth .input-container .phone-number-input input:focus {
  color: white;
}

/* #auth .input-container .phone-number-input .PhoneInput--readOnly input {
  line-height: 53px;
  height: 53px;
} */
#auth .input-container .ant-select-selection-search-input {
  color: white;
}
#auth .input-container .ant-select-selection-search,
#auth .input-container .ant-select-selection-item,
#auth .input-container .ant-select-selection-placeholder {
  inset-inline-start: 0;
  inset-inline-end: 0;
  min-height: 53px;
  line-height: 53px !important;
  font-size: 2rem;
  outline: none;
  color: red;
}
#auth .input-container .ant-select-selection-placeholder {
  color: white !important;
}
#auth .input-container .ant-select {
  outline: none;
}
#auth .input-container .ant-select-arrow,
#auth .input-container .ant-select-clear {
  display: none;
}
#auth .input-container .ant-select-selector {
  box-shadow: none !important;
}

#auth .input-container input::placeholder {
  color: white;
}

#auth .input-container input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 1.5rem;
  width: 1.5rem;
  background-color: rgb(255, 255, 255, 0%);
  border-radius: 5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: .4rem;
  border: .1rem solid white;
}

#auth .input-container input[type="checkbox"]:after {
  font-family: "Font Awesome 6 Free";
  content: "\f00c";
  font-size: 1.3rem;
  color: #0E0035;
  display: none;
  font-weight: 1000;
  padding-top: .3rem;
}

.suggestion {
  top: 3.2rem;
  color: black;
  z-index: 3;
  background-color: #F7F8FC;
  font-size: 1.6rem;
  max-height: 15rem;
}

.suggestion p {
  color: black;
  font-size: 1.6rem;
  font-weight: inherit;
}

#auth .input-container input[type="checkbox"]:checked {
  background-color: white;
}

#auth .input-container input[type="checkbox"]:checked::after {
  display: block;
}

#auth .subsub1-sub1-auth-right .error {
  font-size: 1.5rem;
  color: red;
  text-align: center;
  font-weight: 200;
}

#auth .subsub1-sub1-auth-right .have-account,
#auth .subsub1-sub1-auth-right .forgot-password,
#auth .subsub1-sub1-auth-right .reset {
  font-size: 1.5rem;
  color: white;
  text-align: center;
  margin-bottom: 3rem;
  font-weight: 200;
  cursor: pointer;
}

#auth .subsub1-sub1-auth-right button {
  min-width: 15rem;
  font-size: 2rem;
  background-color: #FD2A22;
  color: white;
  text-align: center;
  border: 0;
  border-radius: 30px;
  font-weight: 500;
  margin-bottom: 2rem;
  margin-left: 0;
  font-family: rmedium;
}

#auth .subsub2-sub1-auth-right {
  display: flex;
  justify-content: center;
}

.imgloading {
  margin-top: 1% !important;
  width: 2rem !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-width: 0 !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  height: 4rem;
  font-size: 1.6rem !important;
}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}


.techwelt-confirm .ant-modal-content {
  background-color: #364153;
}

.techwelt-confirm .ant-modal-confirm-content {
  color: #fff !important;
  text-align: center !important;
  font-size: 2rem !important;
  line-height: 2.4rem !important;
  font-family: rregular;
  margin-bottom: 2rem;
  max-width: 100% !important;
}

.techwelt-confirm .ant-modal-confirm-btns {
  text-align: center;
}

.techwelt-confirm .btn-yes,
.techwelt-confirm .btn-no {
  width: 10rem;
  height: 2.5rem;
  line-height: 1.5rem;
  color: #fff;
  border: .1rem solid #fff;
}

.techwelt-confirm .btn-yes {
  background-color: #D01400;
}

.techwelt-confirm .btn-no {
  background-color: #28A745;
  margin-inline-start: 7rem !important;
}


@media(max-width:1390px) {
  html {
    font-size: 9px;
  }
}

@media(max-width:1256px) {
  html {
    font-size: 8px;
  }

  #auth .main-form {
    height: calc(100dvh - 36rem);
  }
}

@media(max-width:767px) {
  .app {
    position: relative;
    height: 100dvh;
  }

  .right-layout {
    border: none;
    background-color: white;
    overflow-y: hidden;
    border-radius: 0;
  }

  #auth {
    flex-direction: column;
    height: 100dvh;
  }

  #auth .auth-left img {
    height: 40dvh;
  }

  #auth .auth-right {
    position: absolute;
    bottom: 0;
    min-height: 60dvh;
  }

  #auth .auth-right .main-logo {
    width: 350px;
    height: 6rem;
    margin-bottom: 1rem !important;
  }

  #auth .main-form {
    height: max-content;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  #auth .subsub1-sub1-auth-right h3 {
    font-size: 3rem;
    margin-bottom: 2rem !important;
  }

  /* #auth .input-container input {
    font-size: 1.5rem;
  } */

  #auth .subsub2-sub1-auth-right {
    margin-top: 1rem !important;
  }

  #auth .subsub1-sub1-auth-right button {
    font-size: 1.5rem;
  }

  #auth .subsub1-sub1-auth-right .have-account {
    margin-bottom: 1rem;
  }

  #auth .input-container {
    margin-bottom: 2rem !important;
    width: 70%;
  }
  #auth .input-container .ant-select-selector {
    width: 208px;
  }

  .mobile-title {
    box-shadow: 0rem .4rem .5rem rgb(167, 165, 165);
  }
}


.loading-indicator:before {
  content: '';
  background: #000000cc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: 'Loading';
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 1001;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}

.sw-pinit-button,
div.sw-pinit a.sw-pinit-button:hover:before {
  display: none;
}

.ant-checkbox .ant-checkbox-inner:after {
  border-color: #f00 !important;
}

.ant-checkbox .ant-checkbox-inner {
  border-color: #7A7D8B !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #7A7D8B !important;
  background-color: #fff !important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #f00 !important;
  background-color: #fff !important;
}

.ant-radio-wrapper .ant-radio-inner::after {
  background-color: #f00 !important;
  width: 24px !important;
  height: 24px !important;
  margin-block-start: -12px !important;
  margin-inline-start: -12px !important;
}

.ant-radio-wrapper .ant-radio-checked+span {
  color: #f00;
}


.ant-select .ant-select-selection-placeholder {
  color: grey;
  font-size: 1.6rem;
  line-height: inherit !important;
}


.ant-input::placeholder {
  color: grey;
}

/* global scroll bar settings */
.scrollbar-hide {
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: #374957;
  border-radius: 1rem;
  border: .3rem solid transparent;
  background-clip: padding-box; 
}
::-webkit-scrollbar-thumb:hover {
  border: 0;
}

.font-rbold {
  font-family: rbold;
}

.font-rregular {
  font-family: rregular;
}

.font-rmedium {
  font-family: rmedium;
}

.font-rlight {
  font-family: rlight;
}

.font-msmei {
  font-family: msemi;
}