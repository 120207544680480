.subsub1-sub1-rules-div2 {
  height: 4.3rem;
  background-color: #364153;
  display: grid;
  grid-template-columns: 1.1fr 1fr .8fr 1fr .8fr .8fr .2fr;
  grid-template-rows: 1fr;
  grid-template-areas: "h1 h2 h3 h4 h5 h6 h7";
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.subsub1-sub1-rules-div2 p {
  font-size: 1.6rem;
  color: white;
  font-family: rbold;
}

#import-rules {
  display: flex;
  margin-top: .5rem;
}

#sub1-import-rules {
  width: 2.7rem;
  height: 2.7rem;
  font-size: 1.6rem;
  text-align: center;
  margin-top: 1.3rem;
  border-top: .1rem solid #DFE0EB;
  border-bottom: .1rem solid #DFE0EB;
  border-left: .1rem solid #DFE0EB;
  background-color: white;
}

.subsub1-sub2-rules-div2 {
  width: 100%;
  height: 5.2rem;
  border: .1rem solid #DFE0EB;
  background-color: white;
  display: grid;
  grid-template-columns: 1.1fr 1fr .8fr 1fr .8fr .8fr .2fr;
  grid-template-rows: 1fr;
  grid-template-areas: "b1 b2 b3 b4 b5 b6 b7";
  border-radius: 1rem;
}

.subsub1-sub2-rules-div2 .item {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.subsub1-sub2-rules-div2 p {
  font-family: rregular;
  color: #7A7D8B;
  font-size: 1.5rem;
}

.rules-dropdown {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.rules-dropdown-img {
  height: 1.6rem;
  cursor: pointer;
}

.rules-dropdown-div {
  width: 15rem;
  border-radius: 1rem !important;
  border: .1rem solid rgb(122, 125, 139, 50%);
  box-shadow: 0 2px 7px 0px #aaa;
}

.subsub1-sub1-rules-dropdown-div {
  width: 11.5rem;
  height: 1.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.subsub1-sub1-rules-dropdown-div p {
  font-size: 1.6rem;
  color: #374957;
  padding-top: 1rem;
}

.subsub2-sub1-rules-dropdown-div {
  width: 5.7rem;
  height: 1.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.subsub2-sub1-rules-dropdown-div p {
  font-size: 1.6rem;
  color: #374957;
  padding-top: 1rem;
}

.subsub3-sub1-rules-dropdown-div {
  width: 8.7rem;
  height: 1.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.subsub3-sub1-rules-dropdown-div p {
  font-size: 1.6rem;
  color: #374957;
  padding-top: 1rem;
}

.subsub4-sub1-rules-dropdown-div {
  width: 10.5rem;
  height: 1.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.subsub4-sub1-rules-dropdown-div p {
  font-size: 1.6rem;
  color: #374957;
  padding-top: 1rem;
}

@media(max-width:767px) {
  .rules-main {
    padding: 5rem 1rem 0 1rem;
  }

  .subsub1-sub2-rules-div2 {
    height: inherit;
    background-color: #364153;
    border-radius: 1rem;
  }

  .rules-main .sub2-div2 {
    height: calc(100dvh - 34rem) !important;
  }

  .rules-dropdown-div {
    width: 14rem !important;
  }
}

.rule-edit-modal {
  font-size: 1.6rem;
}

.rule-edit-modal .ant-checkbox-wrapper,
.rule-edit-modal .ant-radio-wrapper {
  font-size: 1.6rem;
  font-weight: 400;
  font-family: rregular;
}

.rule-option-label {
  color: #7A7D8B;
  font-size: 1.2rem;
}
.rule-option-value input.ant-input {
  color: #D01400;
  text-align: center;
}
.rule-option-value input.ant-input::placeholder {
  color: #D01400;
}
.rule-option-value.type2 input.ant-input,
.rule-option-value.type2 input.ant-input::placeholder {
  color: #005EEC;
}