.raw-item {
  border: 1px solid transparent;
  font-size: 12px;
}

.raw-item:hover {
  border-color: rgb(134, 21, 9);
}

.raw-item.active {
  border-color: rgba(208, 20, 0, 1);
}

span.treegrid-expander {
  text-align: center;
}

span.treegrid-expander i.fa {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
}

span.treegrid-expander i.fa.fa-plus {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

span.treegrid-expander i.fa.fa-minus {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.traffics .table-striped td {
  background-color: white !important;
  --bs-table-bg-type: white !important;
  font-size: 12px;
}