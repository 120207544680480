.alerts-main .field-input input {
  border: none;
  text-align: center;
}

.sub2-subsub1-sub2-div1 .field-input .ant-select-selector {
  max-height: 100%;
  border: 0;
}

.subsub1-sub1-alerts-div2 {
  height: 4.3rem;
  background-color: #364153;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1.2fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "h1 h2 h3 h4 h5";
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.subsub1-sub1-alerts-div2 p {
  font-size: 1.6rem;
  color: white;
  font-family: rbold;
}

#import-alerts {
  display: flex;
  margin-top: .5rem;
}

#sub1-import-alerts {
  background-color: white;
  width: 2.7rem;
  height: 2.7rem;
  font-size: 1.6rem;
  text-align: center;
  margin-top: 1.3rem;
  border-top: .1rem solid #DFE0EB;
  border-bottom: .1rem solid #DFE0EB;
  border-left: .1rem solid #DFE0EB;
}

.subsub1-sub2-alerts-div2 {
  width: 100%;
  height: 5.2rem;
  border-top: .1rem solid #DFE0EB;
  border-left: .1rem solid #DFE0EB;
  border-bottom: .1rem solid #DFE0EB;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1.2fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "b1 b2 b3 b4 b5 b";
  border-radius: 1rem;
}

.subsub1-sub2-alerts-div2 .item {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.subsub1-sub2-alerts-div2 p {
  font-family: rregular;
  color: #7A7D8B;
  font-size: 1.5rem;
}

@media(max-width:767px) {
  .alerts-main {
    padding: 5rem 1rem 0 1rem;
  }

  .subsub1-sub2-alerts-div2 {
    height: inherit;
    background-color: #364153;
    border-radius: 1rem;
  }

  .alerts-main .sub2-div2 {
    height: calc(100dvh - 38rem) !important;
  }
}