.acs-panel {
  position: relative;
  background-color: #F7F8FC;
  width: 100%;
  height: 100%;
  padding: 3.5rem;
  font-size: 1.4rem;
}

.acs-panel .panel-title {
  color: #D01400;
  font-size: 1.6rem;
  font-family: rbold;
  margin-bottom: 3rem;
}

.acs-panel .panel-body {
  height: calc(100vh - 250px);
  border-radius: 2rem;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.25);
  margin: 0 3rem;
  padding: 3rem 3rem 2rem;
  overflow: auto;
}

.acs-panel .info-item {
  width: 100%;
  color: #FFF;
  background-color: #D9D9D9;
  font-family: rmedium;
  border-radius: .5rem;
  padding: .4rem 1rem;
}

.acs-panel .info-item> :first-child {
  background-color: #D01400;
  padding: .5rem 1rem;
  border-radius: .5rem;
  display: flex;
  align-items: center;
}

.acs-panel .info-item> :last-child {
  background-color: #7A7D8B;
  padding: .5rem 1rem;
  border-radius: .5rem;
}

.acs-panel button.tab-button {
  font-size: 1.6rem;
  min-width: 12rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-family: rregular;
}

.acs-panel .action-item {
  font-size: 1.4rem;
}

.acs-panel .table-header {
  background-color: #364153;
  padding: 1.2rem;
  padding-left: 4rem;
  border-radius: 1rem;
  display: grid;
  grid-template-rows: 1fr;
  font-family: rbold;
  color: #FFF;
}

.acs-panel .table-body>div {
  background-color: #FFF;
  padding: 1.2rem;
  padding-left: 4rem;
  border-radius: 1rem;
  display: grid;
  grid-template-rows: 1fr;
  font-family: rmedium;
  color: #898A8D;
  border: 1px solid #DFE0EB;
}

.acs-panel .table-body>div>div {
  height: 2.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


.acs-panel .userlist .table-header {
  grid-template-columns: .2fr .8fr .8fr .8fr 1fr .8fr .8fr .2fr;
}

.acs-panel .userlist .table-body {
  height: calc(100vh - 510px);
}

.acs-panel .userlist .table-body>div {
  grid-template-columns: .2fr .8fr .8fr .8fr 1fr .8fr .8fr .2fr;
}

.acs-panel .accesslogs .table-header {
  grid-template-columns: .4fr .7fr .8fr 1.4fr .8fr .6fr 1.4fr;
}

.acs-panel .accesslogs .table-body {
  height: calc(100vh - 510px);
}

.acs-panel .accesslogs .table-body>div {
  grid-template-columns: .4fr .7fr .8fr 1.4fr .8fr .6fr 1.4fr;
}

.red-button {
  min-width: 12rem;
  padding: 0.5rem 1rem;
  color: white;
  background-color: #D01400;
  border-radius: .5rem;
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.red-button:active {
  box-shadow: none;
}

.acs-panel .add-user-div1 {
  border: none;
  background-color: transparent;
  height: fit-content;
  overflow: hidden;
}

.acs-panel .btn-fpscan {
  min-height: 8.5rem;
  padding: .5rem 1rem;
  border-radius: 2rem;
  background-color: #D8DBE5;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.acs-panel .btn-fpscan:hover {
  background-color: #b3b4b8;
}

.acs-panel .btn-fpscan img.fp {
  width: 5rem;
  height: 6rem;
}
.acs-panel .btn-fpscan img.check {
  width: 1.5rem;
  height: 1.5rem;
}

.acs-panel .add-user-main .react-datepicker__input-container>input {
  width: 22rem;
  height: 3.5rem;
}

.acs-panel .user-import {
  background-color: white;
  width: 80rem;
  max-width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 1.5rem 3rem;
  border-radius: .5rem;
  margin: 0 auto;
  height: calc(100vh - 330px);
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


@media(max-width:767px) {
  .acs-panel {
    padding: 2rem;
  }

  .acs-panel .panel-title {
    padding-top: 2rem;
    font-size: 2rem;
    text-align: center;
  }

  .acs-panel .panel-body {
    height: calc(100svh - 15rem);
    margin: 0 1rem;
    padding: 1rem;
    overflow: auto;
  }

  .acs-panel .react-datepicker__input-container>input,
  .acs-panel .add-user-main .react-datepicker__input-container>input {
    width: 100%;
  }
}