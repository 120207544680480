.add-device-main p {
    font-size: 1.8rem;
    padding-top: 2rem;
    color:#AD0606;
    font-weight: 600;
    padding-left: 5rem;
}

.add-device-main .vehicle-icon {
    width: 5.5rem;
    height: 2rem;
    right: 7rem;
    top: 3.5rem;
}

.add-device-main .add-input-container {
    width: 45rem;
    max-width: 100%;
}
.add-device-main .add-input-container.sensor-item {
    height: 12rem;
}

.mobile-image {
    width: 14rem;
    height: 10rem;
    object-fit: cover;
}

.add-device-main .normal-input {
    border: .1rem solid #DFE0EB;
    height: 4rem;
    padding-left: 1rem;
    outline: none;
    border-radius: .8rem;
    font-family: msemi;
}

.add-device-main .ant-select-selector {
    height: 4rem !important;
    border-radius: .8rem;
    font-family: msemi;
    font-size: 1.6rem;
}
.add-device-main .ant-select-selector .ant-select-selection-item {
    line-height: 3.6rem;
}

.add-device-div1 {
    border: .1rem solid #DFE0EB;
    border-radius: 2rem;
    background-color: rgb(223,224,235,12%);
    font-size: 1.6rem;
    height: calc(100vh - 200px);
    overflow: auto;
}

.right-subsub1-sub1-add-device-div1 {
    border: .1rem solid #DFE0EB;
    border-radius: 2rem;
    height: max-content;
    background-color: white;
}

.sub5-right-subsub1-sub1-add-device-div1 button {
    width: 20rem !important;
    height: 4rem !important;
    background-color: #AD0606;
    color:white;
    font-size: 1.6rem;
    border-radius: 5rem;
    border: 0;
}
.sub5-right-subsub1-sub1-add-device-div1 button:hover {
  background-color: #3751ff;
}
.sub5-right-subsub1-sub1-add-device-div1 button:active {
  background-color: #640404;
}

.step-wrapper {
    display: flex;
    align-items: center;
    gap: .5rem;
    width: 40rem;
    max-width: 100%;
}
.step-wrapper .step {
    width: 6.5rem;
    height: 6.5rem;
    background-color: #F90A86;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1rem;
    box-shadow: 0 .4rem .4rem 0 rgba(0,0,0,.25);
}
.step-wrapper .step.active {
    background-color: #F8971D;
}
.step-wrapper .line {
    flex-grow: 1;
    background-color: #2B6E94;
    height: .4rem;
    border-radius: .2rem;
}


@media(max-width:768px) {
    .add-device-main {
        padding: 2rem 1rem;
        overflow-y: auto;
        background-color: #F3F4F7;
    }

    .add-device-main p {
        text-align: center;
        font-size: 3rem;
        padding-left: 0;
    }

    .add-device-main .vehicle-icon {
        top: 4.5rem;
        right: 4.5rem;
    }

    .add-device-div1 {
        flex-direction: column;
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding: 1rem !important;
        border: none;
        background-color: inherit;
        height: calc(100svh - 12rem);
    }

    .add-device-main .add-input-container {
        width: 100%;
    }
    
    .add-device-main .add-input-container label {
        font-weight: 500;
        font-size: 2rem;
    }

    .add-device-main .normal-input {
        width: 100%;
    }

    .sub5-right-subsub1-sub1-add-device-div1 button {
        width: 100% !important;
    }
}
